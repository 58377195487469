import React from 'react';
import ListDisplayer from '../../../utils/ListDisplayer';
import { GENERIC_MODAL_ACTIONS } from '../../../context/actionTypes';
import { FaUserEdit } from 'react-icons/fa';
import useManageSalesRep from '../../../hooks/salesrep/useManageSalesRep';
import { AiOutlinePlusCircle } from 'react-icons/ai';
import { FiTrash } from 'react-icons/fi';

export default function ManageSalesReps() {
    const [{ salesReps }, { loadSalesReps, createSalesRep }] = useManageSalesRep();
    const columnsOnSummary = [
        {
            label: "Photo",
            key: 'profile_picture',
            colSpan: 1,
            className: '',
            template: (salesRep) => (
                <img className="h-8" src={salesRep.profile_picture ? `data:image/png;base64,${salesRep.profile_picture}` : "/images/pa.png"}
                     alt={`${salesRep.first_name} ${salesRep.last_name}`}/>
            )
        },
        {
            label: 'Prénom',
            key: 'first_name',
            colSpan: 2,
            className: 'font-semibold',
        },
        {
            label: 'Nom',
            key: 'last_name',
            colSpan: 2,
            className: 'font-semibold',
        },
        {
            label: 'Email',
            key: 'email',
            colSpan: 4,
        },
        {
            label: 'Créé le',
            key: 'created_at',
            type: 'date',
            colSpan: 2,
        },
    ];

    const actionsMenu = [
        {
            action: GENERIC_MODAL_ACTIONS.CREATE_OR_UPDATE_SALES_REP,
            label: 'Modifier',
            enabled: (e) => true,
            Icon: FaUserEdit,
        },
        {
            action: GENERIC_MODAL_ACTIONS.DELETE_SALES_REP,
            label: 'Supprimer',
            enabled: (e) => true,
            Icon: FiTrash,
        }
    ];

    return (
        <div className='pt-4 px-4 text-left flex flex-col max-h-screen overflow-hidden text-base'>
        <div className='flex'>
            <button
                className="btn-transition bg-gray-600 hover:bg-opacity-75 mb-4"
                onClick={() => createSalesRep()}
            >
                <AiOutlinePlusCircle className="h-5 w-5" />
                <div>Ajouter un commercial référent</div>
            </button>
        </div>
            <ListDisplayer
                columnsOnSummary={columnsOnSummary}
                items={salesReps}
                actionsMenu={actionsMenu}
                reload={loadSalesReps}
                defaultSortKey="created_at"
            />
        </div>
    );
}
