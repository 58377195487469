import {MESSAGE_TYPE} from "../context/actionTypes";


export enum MessageState {
    PROCESSED = "processed",
    ERROR = "error",
    PROCESSING = "processing",
}

export interface RagOutput {
    source_id: number;
    source: string;
    page: number;
    distance: number;
    chunk: string;
}

export interface ChatMessage {
    type: MESSAGE_TYPE;
    message: string;
    date?: string;
    state?: MessageState;
    rag?: RagOutput[];
}

export function assumeProcessedState(message: ChatMessage) {
    return message.state === undefined || message.state === MessageState.PROCESSED;
}