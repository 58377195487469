import {MdOutlineLoop} from "react-icons/md";
import {MESSAGE_TYPE} from "../../context/actionTypes";
import MarkDownFormater from "./MarkDownFormater";
import {IoCopyOutline} from "react-icons/io5";
import {VscFeedback} from "react-icons/vsc";
import {FaArrowRight} from "react-icons/fa";
import {setBottomBarSucces} from "../../utils/Functions";
import {twMerge} from "tailwind-merge";
import DistanceMatchComponent from "./DistanceMatchComponent";
import {SlArrowDown, SlArrowUp} from "react-icons/sl";
import useAffichage from "../administration/manageTechnical/Affichage/useAffichage";
import DownButton from "./DownButton";
import styles from "./MessagesDisplayer.module.css";
import {ChatMessage, MessageState, RagOutput} from "../../types/chat";
import {Dispatch} from "react";
import {Action} from "../../context/Context";


interface SourceDisplayerProps {
    rag: RagOutput;
    displayRag: (rag: RagOutput) => void;
    percentages: boolean;
}

function SourceDisplayer({rag, displayRag, percentages}: SourceDisplayerProps) {
    return <div className="flex gap-x-8">
        <div
            onClick={() => displayRag(rag)}
            data-tooltip-id="doc-tooltip"
            data-tooltip-content={`${rag.source} - Page ${rag.page}`}
            className="cursor-pointer flex justify-start gap-1 items-center text-xs font-semibold"
        >
            <FaArrowRight className="h-3 w-3"/>
            <div className=" underline ">{`${rag.source} - Page ${rag.page}`}</div>
        </div>
        <DistanceMatchComponent distance={rag.distance} maxDistance={32} percentages={percentages}/>
    </div>;
}


type RagDisplayerProps = {
    index: number;
    message: ChatMessage;
    selectedMessage: number;
    setSelectedMessage: Dispatch<number>;
} & Omit<SourceDisplayerProps, "rag">

function RagDisplayer({index, message, selectedMessage, setSelectedMessage, ...props}: RagDisplayerProps) {
    return <div className="pt-2">
        <hr className="pt-2"/>
        {selectedMessage === index ? (
            <>
                <div
                    className="text-sm font-semibold flex gap-1 items-center cursor-pointer"
                    onClick={() => setSelectedMessage(null)}>Sources
                    ({message?.rag?.length}) <SlArrowUp className="h-3 w-3"/></div>
                <div className="pt-4">
                    {message?.rag?.map((rag, key) => <SourceDisplayer key={key} rag={rag} {...props} />)}
                </div>
            </>
        ) : (
            <div
                className="text-sm font-semibold flex gap-1 items-center cursor-pointer"
                onClick={() => setSelectedMessage(index)}
            >
                Sources ({message?.rag?.length})
                <SlArrowDown className="h-3 w-3"/>
            </div>
        )
        }
    </div>;
}


interface AssistantMessageButtonsProps {
    index: number;
    message: ChatMessage;
    dispatch: Dispatch<Action>;
    handleRetry: (index: number) => void;
    createFeedback: (question: ChatMessage, response: ChatMessage) => void;
    messages: ChatMessage[];
}

function AssistantMessageButtons({index, message, dispatch, handleRetry, createFeedback, messages}: AssistantMessageButtonsProps) {
    return <>
        <IoCopyOutline
            data-tooltip-id="chat-tooltip"
            data-tooltip-content={"Copier"}
            className="w-5 h-5 cursor-pointer hover:scale-110 transform duration-300 z-10 outline-none"
            onClick={() => {
                navigator.clipboard.writeText(message.message);
                setBottomBarSucces(dispatch, "Message copié");
            }}
        />
        <MdOutlineLoop
            data-tooltip-id="chat-tooltip"
            data-tooltip-content={"Relancer"}
            className="w-5 h-5 cursor-pointer hover:scale-110 transform duration-300 z-10 outline-none"
            onClick={() => handleRetry(index - 1)}
        />
        <VscFeedback
            data-tooltip-id="chat-tooltip"
            data-tooltip-content={"Laisser un avis"}
            className="w-5 h-5 cursor-pointer hover:scale-110 transform duration-300 z-10 outline-none"
            onClick={() => createFeedback(messages[index - 1], message)}
        />
    </>;
}


interface UserErrorMessageButtonsProps {
    index: number;
    handleRetry: (index: number) => void;
}

function UserErrorMessageButtons({index, handleRetry}: UserErrorMessageButtonsProps) {
    return <MdOutlineLoop
        data-tooltip-id="chat-tooltip"
        data-tooltip-content={"Réessayer"}
        className="w-5 h-5 cursor-pointer hover:scale-110 transform duration-300 z-10 outline-none"
        onClick={() => handleRetry(index)}
    />;
}

type SingleMessageDisplayerProps = {
    index: number;
    message: ChatMessage;
    firstMessage: string;
} & Omit<RagDisplayerProps & AssistantMessageButtonsProps & UserErrorMessageButtonsProps, "index" | "message">;

function SingleMessageDisplayer({index, message, firstMessage, ...props}: SingleMessageDisplayerProps) {
    if (index === 0) message.message = firstMessage;
    return message?.type && (
        <div className="text-gray-800 pt-4">
            <div className="flex items-start gap-2 pb-3">
                <div className="pt-3 w-6 h-6">
                    <img
                        src={message?.type === MESSAGE_TYPE.HUMAN ? (message.state === MessageState.ERROR ? "/user_error.svg" : "/user.svg") : "/chatbot-black.svg"}
                        alt="user" className="w-full flex-shrink-0"/>
                </div>
                <div
                    className={`${styles.message} ${twMerge(`${message.type === MESSAGE_TYPE.HUMAN ? (message.state === MessageState.ERROR ? "bg-figmaColors-chatbotErrorMessage text-trueGray-500" : "bg-figmaColors-chatbotUserMessage") : "bg-figmaColors-chatbotAssistantMessage"} p-4 font-light rounded-r-xl rounded-bl-xl shadow-lg transform duration-500 w-full`)}`}>
                    <MarkDownFormater>
                        {message.message}
                    </MarkDownFormater>
                    {message.type === MESSAGE_TYPE.MACHINE && message?.rag?.length > 0 && <RagDisplayer index={index} message={message} {...props}/>}
                </div>
            </div>
            <div className="flex gap-3 justify-end pb-3">
                {index > 0 && <>
                    {message.type === MESSAGE_TYPE.MACHINE && <AssistantMessageButtons index={index} message={message} {...props}/>}
                    {message.state === MessageState.ERROR && <UserErrorMessageButtons index={index} {...props} />}
                </>}
            </div>
        </div>
    );
}

function ChatbotLoadingMessage() {
    return <div className="flex items-center gap-2">
        <div className="w-6 h-6">
            <img src="/chatbot-black.svg" alt="user" className="w-full flex-shrink-0"/>
        </div>
        <div className="inline-block">
            <div className="bg-gray-500 p-2 rounded-lg flex gap-1 text-xl animate-pulse">
                <div>•</div>
                <div>•</div>
                <div>•</div>
            </div>
        </div>
    </div>;
}


type MessagesDisplayerProps = {
    isLoading: boolean;
    bottomRef: React.RefObject<HTMLDivElement>;
    chatContainerRef: React.RefObject<HTMLDivElement>;
    firstMessage: string;
    messages: ChatMessage[];
} & Omit<SingleMessageDisplayerProps, "message" | "index" | "percentages" | "messages" | "firstMessage">

export default function MessagesDisplayer({isLoading, bottomRef, chatContainerRef, firstMessage, messages, ...props}: MessagesDisplayerProps) {
    messages?.length <= 0 && messages.push({message: firstMessage, type: MESSAGE_TYPE.MACHINE});
    const [{percentages}] = useAffichage();
    return <div className="w-full max-w-screen-lg mx-auto px-4">
        {messages.map((message, index) => <SingleMessageDisplayer
            key={index}
            message={message}
            index={index}
            percentages={percentages}
            messages={messages}
            firstMessage={firstMessage}
            {...props}
        />)}
        {isLoading && <ChatbotLoadingMessage/>}

        <DownButton bottomRef={bottomRef} chatContainerRef={chatContainerRef} messages={messages}/>
        <div ref={bottomRef}/>
    </div>;
}