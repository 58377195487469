import {FaArrowDown} from "react-icons/fa";
import {useEffect, useState} from "react";

export default function DownButton({bottomRef, chatContainerRef, messages}) {
    const [gotoBottomButtonVisible, setGotoBottomButtonVisible] = useState(false);
    useEffect(() => {
        const handleScroll = () => {
            if (bottomRef.current) {
                const rect = bottomRef.current.getBoundingClientRect();
                const isVisible = (
                    rect.top >= 0 &&
                    rect.left >= 0 &&
                    rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
                    rect.right <= (window.innerWidth || document.documentElement.clientWidth)
                );
                setGotoBottomButtonVisible(!isVisible);
            }
        };

        const currentChatContainer = chatContainerRef.current;

        if (currentChatContainer) {
            currentChatContainer.addEventListener("scroll", handleScroll);
            // Initial check on component mount
            handleScroll();

            return () => currentChatContainer.removeEventListener("scroll", handleScroll);
        }
    }, [bottomRef, chatContainerRef, messages]);

    return gotoBottomButtonVisible && <div className="flex justify-center sticky bottom-5 z-10 pointer-events-none">
        <FaArrowDown
            onClick={() => bottomRef?.current?.scrollIntoView({behavior: "smooth"})}
            className={`bg-figmaColors-darkBackground text-white p-1 rounded-full pointer-events-auto opacity-70 hover:opacity-100 hover:cursor-pointer transition-all duration-300`}
        />
    </div>;
}