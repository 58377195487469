import {useEffect, useRef, useState} from "react";
import {useAppContext} from "../../context/Context";
import api from "../../utils/api";
import {useParams} from "react-router-dom";
import {ACTION, CHAT_MODE, GENERIC_MODAL_ACTIONS, MESSAGE_TYPE} from "../../context/actionTypes";
import {setBottomBarError, setBottomBarWarning} from "../../utils/Functions";
import {fetchHasFunction, FunctionOption} from "../options/useCheckFunctionOption";
import {isChatMode} from "../../components/chat/fonctions";
import {assumeProcessedState, ChatMessage, MessageState, RagOutput} from "../../types/chat";


export default function useChat() {
    const defaultIntroductoryText =
        "Bonjour et bienvenue sur Virteem Companion ! Je suis un assistant conçu pour vous aider à trouver des informations précises dans notre base de connaissances. Posez-moi des questions spécifiques sur notre domaine d'expertise, et je ferai de mon mieux pour vous fournir une réponse pertinente basée sur les documents disponibles. Si vous avez des questions générales ou souhaitez simplement discuter, je risque de ne pas être très bavard. Essayez de poser une question précise pour obtenir des résultats optimaux. Merci !";
    const [firstText, setfirstText] = useState("");
    const n = 2;
    const [, dispatch] = useAppContext();
    const chatContainerRef = useRef<HTMLDivElement>(null);
    const bottomRef = useRef(null);
    const [isLargerThanInitial, setIsLargerThanInitial] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [initialHeight, setInitialHeight] = useState(0);
    const {id} = useParams();
    const [messages, setMessages] = useState<ChatMessage[]>(() => {
        const storedMessages = localStorage.getItem("conversation");
        return storedMessages ? JSON.parse(storedMessages) : [];
    });

    const message = useRef(null);
    const [withHistory, setWithHistory] = useState(() => {
        const storedHistory = localStorage.getItem("keepHistory");
        return storedHistory ? JSON.parse(storedHistory) : false;
    });
    // Initialised at undefined when the request isn't completed yet. It gets set to true of false depending on if the company has the function or not.
    const [hasLLMChatPureOption, setHasLLMChatPureOption] = useState<boolean | undefined>();
    const [mode, setMode] = useState(() => {
        const storedMode = localStorage.getItem("mode");
        return storedMode ? JSON.parse(storedMode) : CHAT_MODE.QUERY;
    });
    const chatMode = isChatMode(mode);
    const [selectedMessage, setSelectedMessage] = useState(null);
    const [searchResponse, setSearchResponse] = useState(null);
    const [confirmModal, setConfirmModal] = useState(null);
    const [isButtonDisabled, setIsButtonDisabled] = useState(true);
    const [pageLoaded, setPageLoaded] = useState(false);

    const handleMessageChange = () => {
        if (message.current?.value.trim() === "" || isLoading) {
            setIsButtonDisabled(true);
        } else {
            setIsButtonDisabled(false);
        }
    };

    // Set the last machine message as selected by default, or when a new message is added
    useEffect(() => {
        const saveMessages: ChatMessage[] = messages
            .map((message: ChatMessage) => {
                const copy = {...message};
                if (message.state === MessageState.PROCESSING) {
                    copy.state = MessageState.ERROR;
                }
                return copy;
            });
        localStorage.setItem("conversation", JSON.stringify(saveMessages));
        setSelectedMessage(messages.slice().reverse().findIndex(message => message?.type === MESSAGE_TYPE.MACHINE));
    }, [messages, messages.length]);

    useEffect(() => {
        localStorage.setItem("keepHistory", JSON.stringify(withHistory));
    }, [withHistory]);

    useEffect(() => {
        if (hasLLMChatPureOption === false && mode === CHAT_MODE.QUERY_WITHOUT_RAG) {
            setMode(CHAT_MODE.QUERY);
        }
    }, [hasLLMChatPureOption, mode]);

    useEffect(() => {
        localStorage.setItem("mode", JSON.stringify(mode));
        // TODO 'Historique' to be added. For now enable it only when using pure LLM
        if (mode !== CHAT_MODE.QUERY_WITHOUT_RAG) {
            setWithHistory(false);
        }
    }, [mode]);

    useEffect(() => {
        if (message.current) {
            setInitialHeight(message.current.offsetHeight);
        }
    }, []);

    useEffect(() => {
        if (pageLoaded)
            bottomRef?.current?.scrollIntoView({behavior: "smooth"});
        handleMessageChange();
    }, [isLoading, pageLoaded]);

    useEffect(() => {
        setTimeout(() => {
            bottomRef?.current?.scrollIntoView({behavior: "smooth"});
            setPageLoaded(true);
        }, 700);
    }, [chatMode]);

    const toggleHistory = () => {
        setWithHistory((old) => !old);
        /*
        TODO: Check if needed
        if (withHistory) {
            setWithHistory((old) => !old);
        }
        else {
            setConfirmModal({
                text: "Cette option inclut automatiquement les deux derniers messages en contexte.\n\nAttention : Peut provoquer une baisse de précision, cette fonctionnalité est toujours en test.",
                yes: () => (setWithHistory((old) => !old), setConfirmModal(null)),
                no: () => setConfirmModal(null)
            })
        }*/
    };

    const autoAdaptTextArea = () => {
        if (!message.current) {
            return;
        }
        message.current.style.height = "auto"; // Allow the size to reduce
        message.current.style.height = `${message.current.scrollHeight}px`; // Make the textbox size grow if needed
        setIsLargerThanInitial(message.current.offsetHeight > initialHeight); // Reposition the send button
    };

    const handleKeyDown = (event) => {
        if (event.key === "Enter" && !event.shiftKey) {
            event.preventDefault();
            handleSend();
        }
        autoAdaptTextArea();
    };

    const send = async (messageToSend: string, history = [], modeOverwrite: string): Promise<ChatMessage | null> => {
        try {
            console.log("SENDING", messageToSend, history);
            const response = await api.post(`/request/${modeOverwrite}/${id}`, {
                query: messageToSend,
                history,
            });
            if (response?.data?.detail) {
                setBottomBarWarning(dispatch, response.data.detail);
            }
            if (isChatMode(mode)) {
                return {
                    type: MESSAGE_TYPE.MACHINE,
                    message: response.data.completion.choices[0].message.content,
                    date: response.data.created,
                    rag: response.data.rag ? Object.values(response.data.rag) : [],
                };
            } else {
                return {...response.data, rag: response.data.rag ? Object.values(response.data.rag) : []};
            }
        } catch (error) {
            console.log("Error sending message: ", error);
            setBottomBarError(dispatch, error);
        }
    };

    const handleSend = async () => {
        if (isButtonDisabled) return;
        setIsLoading(true);
        const messageToSend = message.current.value;
        if (isChatMode(mode)) {
            const humanMessage: ChatMessage = {
                type: MESSAGE_TYPE.HUMAN,
                message: message.current.value,
                date: new Date().toISOString(),
                state: MessageState.PROCESSING,
            };
            const prevMessages = messages;
            setMessages([...prevMessages, humanMessage]);
            const nonErrorMessages = messages.filter(message => assumeProcessedState(message));

            message.current.value = "";

            const history = withHistory
                ? nonErrorMessages
                    .slice(Math.max(nonErrorMessages.length - n, 1))
                    .map(element => ({
                        message: element.message,
                        sender: element.type,
                    }))
                : [];
            const aiResponse = await send(messageToSend, history, (mode === CHAT_MODE.QUERY && withHistory) ? CHAT_MODE.QUERY_WITH_HISTORY : mode);
            console.log("RESPONSE", aiResponse);
            if (aiResponse) {
                humanMessage.state = MessageState.PROCESSED;
                setMessages([...prevMessages, humanMessage, aiResponse]);
            } else {
                humanMessage.state = MessageState.ERROR;
                setMessages([...prevMessages, humanMessage]);
            }
        } else {
            const aiResponse = await send(messageToSend, [], mode);
            setSearchResponse(aiResponse);
        }
        setIsLoading(false);
    };

    const createFeedback = (question: ChatMessage, response: ChatMessage) => {
        dispatch({
            type: ACTION.SET_GENERIC_MODAL,
            payload: {
                props: {question, response, companyId: id},
                action: GENERIC_MODAL_ACTIONS.CREATE_FEEDBACK,
            },
        });
    };

    const handleRetry = async (index: number) => {
        if (isLoading) return;
        setIsLoading(true);
        const updatedMessages = messages.slice(0, index + 1);
        updatedMessages[index].state = MessageState.PROCESSING;
        setMessages(updatedMessages);
        const messageToSend = updatedMessages[index].message;

        //History with the 2 messages before the current one
        const nonErrorMessages = updatedMessages.filter(message => assumeProcessedState(message));
        const history = withHistory
            ? nonErrorMessages
                .slice(Math.max(nonErrorMessages.length - n, 1))
                .map(element => ({
                    message: element.message,
                    sender: element.type,
                }))
            : [];
        const machineMessage = await send(messageToSend, history, (mode === CHAT_MODE.QUERY && withHistory) ? CHAT_MODE.QUERY_WITH_HISTORY : mode);
        if (machineMessage) {
            updatedMessages[index].state = MessageState.PROCESSED;
            setMessages([...updatedMessages, machineMessage]);
        } else {
            updatedMessages[index].state = MessageState.ERROR;
            setMessages([...updatedMessages]);
        }
        setIsLoading(false);
    };

    const deleteConversation = () => {
        setConfirmModal({
            text: "Êtes-vous sûrs de vouloir supprimer la conversation ? Cette action est irréversible.",
            yes: () => {
                localStorage.removeItem("conversation");
                setMessages([]);
                setConfirmModal(null);
            },
            no: () => setConfirmModal(null),
        });
    };


    const displayRag = (rag: RagOutput) => {
        dispatch({
            type: ACTION.SET_GENERIC_MODAL,
            payload: {
                action: GENERIC_MODAL_ACTIONS.DISPLAY_RAG,
                props: rag,
            },
        });
    };


    const getIntroductoryMessage = async () => {
        try {
            const uuid = window.location.href.split("/").pop();
            var response = await api.get(`/company/${uuid}/text`);
            if (!response.data) response.data = defaultIntroductoryText;
            setfirstText(response.data);
        } catch (error) {
            console.error("Failed to load introductory text", error);
            setBottomBarError(dispatch, error);
            setfirstText(defaultIntroductoryText);
        }
    };
    const checkUuid = async () => {
        try {
            const uuid = window.location.href.split("/").pop();
            await api.get(`/request/check/${mode}/${uuid}`);
            return true;
        } catch (error) {
            console.error("Link is damaged, check the link again, you'll soon be sent to the homePage", error);
            window.location.href = "https://www.virteem-companion.fr/";
            setBottomBarError(dispatch, error);
            return false;
        }
    };
    useEffect(() => {
        checkUuid().then(result => {
            if (result) {
                getIntroductoryMessage();
                fetchHasFunction(dispatch, FunctionOption.LLM_CHAT_PURE, id).then(setHasLLMChatPureOption).catch(console.error);
            }
        });
    }, [dispatch, id]);

    return [{
        message,
        messages,
        chatContainerRef,
        bottomRef,
        isLargerThanInitial,
        withHistory,
        hasLLMChatPureOption,
        isLoading,
        mode,
        selectedMessage,
        searchResponse,
        confirmModal,
        firstText,
        isButtonDisabled,
    }, {
        handleKeyDown,
        handleMessageChange,
        autoAdaptTextArea,
        handleSend,
        createFeedback,
        handleRetry,
        setMode,
        setSelectedMessage,
        deleteConversation,
        displayRag,
        setConfirmModal,
        toggleHistory,
        dispatch,
        getIntroductoryMessage,
    }];
}
