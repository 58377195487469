import ReactMarkdown from "react-markdown";
import remarkBreaks from "remark-breaks";
import remarkGfm from "remark-gfm";
import rehypeHighlight from "rehype-highlight";
import CodeBlock from "./CodeBlock";
import styles from "./MarkDownFormatter.module.css";

export default function MarkDownFormater({children}) {
    return <ReactMarkdown
        className={`w-full ${styles.markdownFormatter} text-sm sm:text-base`}
        rehypePlugins={[rehypeHighlight]}
        remarkPlugins={[remarkGfm, remarkBreaks]}
        components={{
            code: CodeBlock,
            ol: ({node, ...props}) => <ol style={{listStyleType: "decimal", marginLeft: "20px"}} {...props} />,
            ul: ({node, ...props}) => <ul style={{listStyleType: "disc", marginLeft: "20px"}} {...props} />,
            li: ({node, ...props}) => <li {...props} />,
            a: ({node, ...props}) => <a target="_blank" rel="noopener noreferrer" {...props} />,
            strong: ({node, ...props}) => <strong className="font-semibold" {...props} />,
            h1: ({node, ...props}) => <h1 className="text-3xl font-semibold" {...props}/>,
            h2: ({node, ...props}) => <h2 className="text-2xl font-semibold" {...props}/>,
            h3: ({node, ...props}) => <h3 className="text-xl font-semibold" {...props}/>,
            h4: ({node, ...props}) => <h4 className="text-lg font-semibold" {...props}/>,
            h5: ({node, ...props}) => <h5 className="text-base font-semibold" {...props}/>,
            h6: ({node, ...props}) => <h6 className="text-sm font-semibold" {...props}/>,
        }}
    >
        {children}
    </ReactMarkdown>;
}
